import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MyCart = ({toast}) => {

    const [cart, setCart] = useState([]);
    useEffect(() => {
        axios.get('/cart').then((res) => setCart(res.data)).catch((err) => console.log(err));
    }, [cart]);

    return (
        <>
            <div className="page-title-overlap bg-dark pt-4">
                <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                    <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                                <li className="breadcrumb-item"><Link className="text-nowrap" to="/"><i className="ci-home" />Home</Link></li>
                                <li className="breadcrumb-item text-nowrap"><Link to="/shop">Market</Link></li>
                                <li className="breadcrumb-item text-nowrap active" aria-current="page">Cart</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                        <h1 className="h3 text-light mb-0">My cart</h1>
                    </div>
                </div>
            </div>
            <div className="container mb-5 pb-3">
                <div className="bg-light shadow-lg rounded-3 overflow-hidden">
                    <div className="row">
                        <section className="col-lg-8 pt-2 pt-lg-4 pb-4 mb-3">
                            <div className="pt-2 px-4 pe-lg-0 ps-xl-5">
                                <div className="d-flex flex-wrap justify-content-between align-items-center border-bottom pb-3">
                                    <div className="py-1"><Link className="btn btn-outline-accent btn-sm" to="/shop"><i className="ci-arrow-left me-1 ms-n1" />Back to shopping</Link></div>
                                    <div className="d-none d-sm-block py-1 fs-sm">You have {cart.reduce((acc, item) => acc + item.total_qty, 0)} products in your cart</div>
                                </div>
                                {cart && cart.map((item, index) => (
                                    <div key={index} className="d-block d-sm-flex align-items-center py-4 border-bottom">
                                        <Link className="d-block position-relative mb-3 mb-sm-0 me-sm-4 ms-sm-0 mx-auto" to={`/details/${item.product_id}`} style={{ width: '12.5rem' }}>
                                            <img className="rounded-3" src={`../../${item.thumbnail}`} alt={item.product_name} />
                                            <span className="btn btn-icon btn-danger position-absolute top-0 end-0 py-0 px-1 m-2" onClick={(e) => {
                                                e.preventDefault();
                                                axios.delete(`/cart/${item.id}`).then((res) => toast.danger('Item removed from cart')).catch((err) => console.log(err));
                                            }}>
                                                <i className="ci-trash" />
                                            </span>
                                        </Link>
                                        <div className="text-center text-sm-start">
                                            <h3 className="h6 product-title mb-2"><Link to={`/details/${item.product_id}`}>{item.product_name}</Link></h3>
                                            <div className="d-inline-block text-accent">
                                                {item.currency} {item.total_discounted_price}
                                            </div>
                                            <span className="d-inline-block text-accent fs-ms border-start ms-2 ps-2">{item.total_qty} </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <aside className="col-lg-4 ps-xl-5">
                            <hr className="d-lg-none" />
                            <div className="p-4 h-100 ms-auto border-start">
                                <div className="px-lg-2">
                                    <div className="text-center mb-4 py-3 border-bottom">
                                        <h2 className="h6 mb-3 pb-1">Cart total</h2>
                                        <h3 className="fw-normal">
                                            {Object.entries(cart.reduce((acc, item) => {
                                                if (acc[item.currency] === undefined) {
                                                    acc[item.currency] = 0;
                                                }
                                                acc[item.currency] += item.total_discounted_price;
                                                return acc;
                                            }, {})).map(([currency, total]) => (
                                                <> <span key={currency}>{currency} {total.toFixed(2)}</span><br /></>
                                            ))}
                                        </h3>
                                    </div>
                                    <a className="btn btn-primary btn-shadow d-block w-100 mt-4" href="marketplace-checkout.html"><i className="ci-locked fs-lg me-2" />Secure Checkout</a>
                                    <div className="text-center pt-2 pb-3"><small className="text-form text-muted">100% money back guarantee</small></div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCart;