import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

const Customer = () => {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState([]);
    useEffect(() => {
        document.title = "my account";
        axios.get("/customer").then((res) => {
            console.log(res.status)
            if (res.data.error) {
                navigate('/');
            } else {
                setCustomer(res.data);
            }
        })
    }, []);
    return (
        <>
            {customer.map((item) => (
                <div key={item.id}>
                    <h1>{item.name}</h1>
                    <p>{item.email}</p>
                </div>
            ))}
        </>
    );
}

export default Customer;