import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import t from './translations.json';
import axios from 'axios';

const Footer = () => {
    const [lng, setlng] = useState(localStorage.getItem('lng') || 'en');
    const toggleLanguage = () => {
        setlng(lng === 'en' ? 'ar' : 'en');
        localStorage.setItem('lng', lng === 'en' ? 'ar' : 'en');
        window.location.reload();
    };

    const [social, setSocial] = useState([]);
    const [info, setInfo] = useState([]);
    useEffect(() => {
        axios.get('/social').then((res) => {
            setSocial(res.data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get('/info').then((res) => {
            setInfo(res.data[0]);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        const scrollToTop = () => {
            const scrollElement = document.querySelector('.btn-scroll-top');
            if (scrollElement) {
                if (window.scrollY > 600) {
                    scrollElement.classList.add('show');
                } else {
                    scrollElement.classList.remove('show');
                }
            }
        };
        document.addEventListener('scroll', scrollToTop);
        return () => document.removeEventListener('scroll', scrollToTop);
    }, []);

    return (
        <footer className="footer bg-dark pt-5">
            <div className="pt-5 bg-darker">
                <div className="container">
                    <div className="row pb-3">
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="d-flex">
                                <i className="ci-rocket text-primary" style={{ fontSize: '2.25rem' }} />
                                <div className="ps-3">
                                    <h6 className="fs-base text-light mb-1">{t[lng].service_title1}</h6>
                                    <p className="mb-0 fs-ms text-light opacity-50">{t[lng].service_description1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="d-flex">
                                <i className="ci-currency-exchange text-primary" style={{ fontSize: '2.25rem' }} />
                                <div className="ps-3">
                                    <h6 className="fs-base text-light mb-1">{t[lng].service_title2}</h6>
                                    <p className="mb-0 fs-ms text-light opacity-50">{t[lng].service_description2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="d-flex">
                                <i className="ci-support text-primary" style={{ fontSize: '2.25rem' }} />
                                <div className="ps-3">
                                    <h6 className="fs-base text-light mb-1">{t[lng].service_title3}</h6>
                                    <p className="mb-0 fs-ms text-light opacity-50">{t[lng].service_description3}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="d-flex">
                                <i className="ci-card text-primary" style={{ fontSize: '2.25rem' }} />
                                <div className="ps-3">
                                    <h6 className="fs-base text-light mb-1">{t[lng].service_title4}</h6>
                                    <p className="mb-0 fs-ms text-light opacity-50">{t[lng].service_description4}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="hr-light mb-5" />
                    <div className="row pb-2">
                        <div className="col-md-6 text-center text-md-start mb-4">
                            <div className="text-nowrap mb-4">
                                <Link className="d-inline-block align-middle mt-n1 me-3" to="/">
                                    <span className="text-white">Hi</span><span className="text-primary">Store</span>
                                </Link>
                                <div className="btn-group dropdown disable-autohide">
                                    <button className="btn btn-outline-light border-light btn-sm dropdown-toggle px-2" type="button" data-bs-toggle="dropdown">{lng === 'en' ? 'English' : 'العربية'}</button>
                                    <ul className="dropdown-menu my-1">
                                        <li>
                                            <span type="button" className="dropdown-item pb-1" onClick={toggleLanguage}>{lng === 'en' ? 'العربية' : 'English'}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="widget widget-links widget-light">
                                <p className="fs-sm text-light opacity-50 w-75">{info.description}</p>
                                <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                                    <li className="widget-list-item me-4">
                                        <a className="widget-list-link" href={`tel:${info.phone_no}`}>
                                            <i className="ci-phone opacity-60 me-2" />
                                            {info.phone_no}
                                        </a>
                                    </li>
                                    <li className="widget-list-item me-4">
                                        <a className="widget-list-link" href={`mailto:${info.email}`}>
                                            <i className="ci-mail opacity-60 me-2" />
                                            {info.email}
                                        </a>
                                    </li>
                                    <li className="widget-list-item me-4">
                                        <i className="ci-location opacity-60 me-2" />
                                        {info.address}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 text-center text-md-end mb-4">
                            <div className="mb-3">
                                {social.map((item, index) => (
                                    <a key={index} className="btn-social bs-light" href={item.social_link} target='_blank' rel='noopener noreferrer' title={item.social_name}>
                                        <div className='icon-helper' dangerouslySetInnerHTML={{ __html: item.social_icon }} />
                                    </a>
                                ))}
                            </div>
                            <img className="d-inline-block" src="../img/cards-alt.png" width={187} alt="Payment methods" />
                        </div>
                    </div>
                    <div className="pb-4 fs-xs text-light opacity-50 text-center text-md-start">© {new Date().getFullYear()}. Developed by <a className="text-light" href="https://samserver.dev" target="_blank" rel="noopener">Sam Server</a></div>
                </div>
            </div>
            <span type="button" className="btn-scroll-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} ><span className="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span><i className="btn-scroll-top-icon ci-arrow-up"> </i></span>
        </footer>

    );
}

export default Footer;