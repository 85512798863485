import React from 'react';
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function App({ children }) {
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, { toast });
  });

  return (
    <>
      <main className="page-wrapper">
        <ToastContainer />
        <Header />
        {childrenWithProps}
      </main>
      <Footer />
    </>
  );
}

export default App;
