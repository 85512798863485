import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import t from './translations.json';
import axios from 'axios';

const Header = () => {
    const [lng, setLng] = useState(localStorage.getItem('lng') || 'en');
    const [info, setInfo] = useState({});
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();

    const toggleLanguage = (lang) => {
        setLng(lang);
        localStorage.setItem('lng', lang);
        window.location.reload();
    };

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        navigate(`/result/${searchValue}`);
    };

    useEffect(() => {
        axios.get('/info').then((res) => setInfo(res.data[0])).catch((err) => console.log(err));
        axios.get('/cart').then((res) => setCart(res.data)).catch((err) => console.log(err));
    }, []);

    useEffect(() => {
    }, [cart]);

    useEffect(() => {
        const handleScroll = () => {
            const navbarSticky = document.querySelector('.navbar-sticky');
            if (navbarSticky !== null) {
                const t = navbarSticky.offsetHeight;
                if (navbarSticky.classList.contains('position-absolute')) {
                    if (500 < window.pageYOffset) {
                        navbarSticky.classList.add('navbar-stuck');
                    } else {
                        navbarSticky.classList.remove('navbar-stuck');
                    }
                } else {
                    if (500 < window.pageYOffset) {
                        document.body.style.paddingTop = `${t}px`;
                        navbarSticky.classList.add('navbar-stuck');
                    } else {
                        document.body.style.paddingTop = '';
                        navbarSticky.classList.remove('navbar-stuck');
                    }
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
    }, []);

    const toggleClass = (className, toggle) => {
        const element = document.querySelector(`.${className}`);
        if (toggle === 'show') {
            element.classList.toggle('show');
        } else {
            element.classList.toggle('d-none');
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = {};
        for (let key of formData.keys()) {
            data[key] = formData.get(key);
        }
        if (data.email === '' && data.phone_number === '') {
            alert('Please provide email or phone number');
            return;
        }
        if (data.password !== data.password_confirm) {
            alert('Password not matched');
            return;
        }
        delete data.password_confirm;

        axios.post('/signup', data).then((res) => {
            if (res.status === 200) {
                form.reset();
                setTimeout(() => {
                    navigate('/my-account');
                }, 500);
            }
        }).catch((err) => console.log(err));
    }

    const [customer, setCustomer] = useState([]);
    const handleLogin = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = {};
        for (let key of formData.keys()) {
            data[key] = formData.get(key);
        }
        if (data.email) {
            data.username = data.email;
            delete data.email;
        } else {
            data.username = data.phone_number;
            delete data.phone_number;
        }
        axios.post('/login', data).then((res) => {
            if (res.status === 200) {
                setCustomer([res.data]);
                window.$('#signin-modal').modal('hide');
                form.reset();
                setTimeout(() => {
                    navigate('/my-account');
                }, 500);
            }
        }).catch((err) => console.log(err));
    }

    return (
        <header className="shadow-sm">
            <div className="modal fade" id="signin-modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#signin-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-unlocked me-2 mt-n1" />Sign in</a></li>
                                <li className="nav-item"><a className="nav-link fw-medium" href="#signup-tab" data-bs-toggle="tab" role="tab" aria-selected="false"><i className="ci-user me-2 mt-n1" />Sign up</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body tab-content py-4">
                            <form onSubmit={(e) => handleLogin(e)} className="needs-validation tab-pane fade show active" autoComplete="off" id="signin-tab">
                                <div className="mb-3" id='dev_siphone'>
                                    <div className="mb-3 d-flex flex-wrap justify-content-between">
                                        <label>Phone Number</label>
                                        <span type="button" className="fs-sm text-danger" onClick={(e) => {
                                            const email = document.getElementById('dev_siemail');
                                            const phone = document.getElementById('dev_siphone');
                                            email.style.display = 'block';
                                            phone.style.display = 'none';
                                            const emailInput = document.getElementById('si-email');
                                            const phoneInput = document.getElementById('si-phone');
                                            emailInput.setAttribute('required', 'required');
                                            phoneInput.removeAttribute('required');
                                            phoneInput.value = ''; // Clear phone input value
                                        }}>Use Email?</span>
                                    </div>
                                    <input className="form-control" type="text" name='phone_number' id="si-phone" placeholder="07514584888" defaultValue={'07514584888'} required />
                                </div>
                                <div className="mb-3" id='dev_siemail' style={{ "display": "none" }}>
                                    <div className="mb-3 d-flex flex-wrap justify-content-between">
                                        <label>Email address</label>
                                        <span type="button" className="fs-sm text-danger" onClick={(e) => {
                                            const email = document.getElementById('dev_siemail');
                                            const phone = document.getElementById('dev_siphone');
                                            email.style.display = 'none';
                                            phone.style.display = 'block';
                                            const emailInput = document.getElementById('si-email');
                                            const phoneInput = document.getElementById('si-phone');
                                            phoneInput.setAttribute('required', 'required');
                                            emailInput.removeAttribute('required');
                                            emailInput.value = ''; // Clear email input value
                                        }}>Use Phone Number?</span>
                                    </div>
                                    <input className="form-control" type="email" name='email' id="si-email" placeholder="johndoe@example.com" defaultValue={''} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="si-password">Password</label>
                                    <div className="password-toggle">
                                        <input className="form-control" type="password" name='password' id="si-password" required />
                                        <label className="password-toggle-btn" aria-label="Show/hide password">
                                            <input className="password-toggle-check" type="checkbox" onClick={(e) => {
                                                const password = document.getElementById('si-password');
                                                if (password.type === 'password') {
                                                    password.type = 'text';
                                                } else {
                                                    password.type = 'password';
                                                }
                                            }} /><span className="password-toggle-indicator" />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 d-flex flex-wrap justify-content-between">
                                    <a className="fs-sm" href="#">Forgot password?</a>
                                </div>
                                <button className="btn btn-primary btn-shadow d-block w-100" type="submit">Sign in</button>
                            </form>

                            <form onSubmit={(e) => handleSubmit(e)} className="needs-validation tab-pane fade" id="signup-tab">
                                <div className="mb-3">
                                    <label className="form-label">Full name</label>
                                    <input className="form-control" type="text" name='customer_name' placeholder="John Doe" required />
                                </div>
                                <div className="mb-3" id='dev_email'>
                                    <div className="mb-3 d-flex flex-wrap justify-content-between">
                                        <label>Email address</label>
                                        <span type="button" className="fs-sm text-danger" onClick={(e) => {
                                            const email = document.getElementById('dev_email');
                                            const phone = document.getElementById('dev_phone');
                                            email.style.display = 'none';
                                            phone.style.display = 'block';
                                        }} >Use Phone Number?</span>
                                    </div>
                                    <input className="form-control" type="email" name='email' placeholder="johndoe@example.com" />
                                </div>
                                <div className="mb-3" id='dev_phone' style={{ "display": "none" }}>
                                    <div className="mb-3 d-flex flex-wrap justify-content-between">
                                        <label>Phone No.</label>
                                        <span type="button" className="fs-sm text-danger" onClick={(e) => {
                                            const email = document.getElementById('dev_email');
                                            const phone = document.getElementById('dev_phone');
                                            email.style.display = 'block';
                                            phone.style.display = 'none';
                                        }}  >Use Email?</span>
                                    </div>
                                    <input className="form-control" type="text" name="phone_number" placeholder='07500000000' />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="password-toggle">
                                        <input className="form-control" type="password" name='password' id="su-password" required />
                                        <label className="password-toggle-btn" aria-label="Show/hide password">
                                            <input className="password-toggle-check" type="checkbox" onClick={(e) => {
                                                const password = document.getElementById('su-password');
                                                if (password.type === 'password') {
                                                    password.type = 'text';
                                                } else {
                                                    password.type = 'password';
                                                }
                                            }} /><span className="password-toggle-indicator" />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Confirm password</label>
                                    <div className="password-toggle">
                                        <input className="form-control" type="password" name='password_confirm' id="su-password-confirm" required />
                                        <label className="password-toggle-btn" aria-label="Show/hide password">
                                            <input className="password-toggle-check" type="checkbox" onClick={(e) => {
                                                const password = document.getElementById('su-password-confirm');
                                                if (password.type === 'password') {
                                                    password.type = 'text';
                                                } else {
                                                    password.type = 'password';
                                                }
                                            }} /><span className="password-toggle-indicator" />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Address</label>
                                    <input className="form-control" type="text" name='address' placeholder="1234 Main St." required />
                                </div>
                                <button className="btn btn-primary btn-shadow d-block w-100" type="submit">Sign up</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="topbar topbar-dark bg-dark mb-2">
                <div className="container">
                    <div className="topbar-text dropdown d-md-none">
                        <a className="topbar-link dropdown-toggle" href="#0" data-bs-toggle="dropdown">{t[lng].support}</a>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href={`tel:${info.phone_no}`}><i className="ci-support text-muted me-2" />{info.phone_no}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="topbar-text text-nowrap d-none d-md-inline-block">
                        <i className="ci-support" />
                        <span className="text-muted me-1">{t[lng].support}</span>
                        <a className="topbar-link" href={`tel:${info.phone_no}`}>{info.phone_no}</a>
                    </div>
                    <div className="ms-3 text-nowrap">
                        <div className="topbar-text dropdown disable-autohide">
                            <i className="ci-globe" />
                            <span type="button" className="topbar-link dropdown-toggle" data-bs-toggle="dropdown">
                                {t[lng].language}
                            </span>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <span type="button" className="dropdown-item pb-1" onClick={() => toggleLanguage("cbk")}>سۆرانی</span>
                                </li>
                                <li>
                                    <span type="button" className="dropdown-item pb-1" onClick={() => toggleLanguage("kmr")}>Kurmanji</span>
                                </li>
                                <li>
                                    <span type="button" className="dropdown-item pb-1" onClick={() => toggleLanguage("en")}>English</span>
                                </li>
                                <li>
                                    <span type="button" className="dropdown-item pb-1" onClick={() => toggleLanguage("tr")}>Türkçe</span>
                                </li>
                                <li>
                                    <span type="button" className="dropdown-item pb-1" onClick={() => toggleLanguage("ar")}>العربية</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-sticky bg-light">
                <div className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <Link className="navbar-brand d-none d-sm-block flex-shrink-0" to="/">
                            <span className="text-dark">Hi</span><span className="text-primary">Store</span>
                        </Link>
                        <Link className="navbar-brand d-sm-none flex-shrink-0 me-2" to="/">
                            <span className="text-dark">Hi</span><span className="text-primary">Store</span>
                        </Link>
                        <div className="input-group d-none d-lg-flex mx-4">
                            <input className="form-control rounded-end pe-5" type="text" placeholder={t[lng].search_placeholder} name="search" onKeyDown={(e) => { e.key === 'Enter' && handleSearch(e); }} />
                            <i className="ci-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3" />
                        </div>
                        <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <span className="navbar-tool navbar-stuck-toggler" type="button">
                                <div className="navbar-tool-icon-box">
                                    <i className="navbar-tool-icon ci-menu" onClick={(e) => {
                                        toggleClass('navbar-stuck-menu', 'show')
                                    }} />
                                </div>
                            </span>
                            {customer && customer.length > 0 ?
                                <Link to="/my-account" className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" >
                                    <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-user" /></div>
                                    <div className="navbar-tool-text ms-n3"><small>Hello, {customer[0].customer_name}</small>My Account</div> :
                                </Link> :
                                <a className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" href="#signin-modal" data-bs-toggle="modal" onClick={(e) => {
                                    const emailInput = document.getElementById('si-email');
                                    emailInput.value = '';
                                }}>
                                    <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-user" /></div>
                                    <div className="navbar-tool-text ms-n3"><small>Hello, Sign in</small>Or SignUp</div>
                                </a>
                            }
                            {cart && cart.length > 0 ? (
                                <div className="navbar-tool dropdown ms-3">
                                    <Link className="navbar-tool-icon-box bg-secondary dropdown-toggle" to="/my-cart">
                                        <span className="navbar-tool-label">
                                            {cart.reduce((acc, item) => acc + item.total_qty, 0)}
                                        </span>
                                        <i className="navbar-tool-icon ci-cart" />
                                    </Link>
                                    <Link className="navbar-tool-text" to="/my-cart">
                                        <small>My Cart</small>
                                        {Object.entries(cart.reduce((acc, item) => {
                                            if (acc[item.currency] === undefined) {
                                                acc[item.currency] = 0;
                                            }
                                            acc[item.currency] += item.total_discounted_price;
                                            return acc;
                                        }, {})).map(([currency, total]) => (
                                            <> <span key={currency}>{currency} {total.toFixed(2)}</span><br /></>
                                        ))}
                                    </Link>
                                </div>
                            ) : (
                                <div className="navbar-tool dropdown ms-3">
                                    <span className="navbar-tool-icon-box bg-secondary dropdown-toggle">
                                        <span className="navbar-tool-label">0</span>
                                        <i className="navbar-tool-icon ci-cart" />
                                    </span>
                                    <span className="navbar-tool-text">
                                        <small>My Cart</small>$0.00
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
                    <div className="container">
                        <div className={`collapse navbar-collapse ${lng === 'ar' || lng === 'cbk' ? 'rtl' : ''}`}>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">{t[lng].home}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/shop">{t[lng].shop}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/new">{t[lng].new}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/discount">{t[lng].discount}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/collections">{t[lng].collections}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/brands">{t[lng].brands}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;